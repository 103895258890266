<template>
  <div
    class="auth-layout-wrap demo"
    :style="{ backgroundImage: 'url(' + bgImage + ')' }"
  >
    <b-container style="max-width: 800px;">
      <b-row>
        <b-col class="text-right">
          <b-button variant="info" @click="$auth.logout()">Logout</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <h1>Dashboard</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="m-1">
            <p class="text-center">Select your sensor</p>
            <b-form-select class="m-1" v-model="deviceSelected" :options="sensors" text-field="label" value-field="value"></b-form-select>
          </div>
          <div style="width:100%" class="text-center mt-2 mb-2">
            <b-button class="measurement" variant="primary"  @click="toggleMeasurements(deviceSelected, item.name), item.disabled = !item.disabled" 
                v-bind:class="{ 'disabled' : item.disabled}" size="sm"
                v-for="(item, index) in seriesButtons" :key="index">
                  {{last_values[item.name]}} <br/>
                  {{item.name}}
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
          <b-col lg="12">
              <highcharts :constructorType="'stockChart'" class="hc" :options="chart" ref="chart" style="height: calc(100vh - 20em)"></highcharts>
          </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock"
import Exporting from "highcharts/modules/exporting"
Stock(Highcharts);
Exporting(Highcharts);
import {Chart} from 'highcharts-vue';

export default {
  metaInfo: {
    title: "Sensors dashboard"
  },
  components: {
      highcharts : Chart,
  },
  data: () => {
      return {
        sensors: [],
        avg_temperature: 0,
        avg_humidity: 0,
        bgImage: require("@/assets/images/background-login.jpg"),
        logo: require("@/assets/images/logo-inverse.png"),
        devices: [],
        deviceSelected: [],
        last_values: {},
        cC: true,
        cT: true,
        cL: true,
        cH: true,
        chart: {
            series: [
                
            ],
            xAxis: {
                type: 'datetime',
            },
            navigation: {
                buttonOptions: {
                    theme: {
                        // Good old text links
                        style: {
                            color: '#039',
                            textDecoration: 'underline'
                        }
                    }
                }
            },
        },
        seriesButtons: [],
        serieSelectedButtons: null,
        uO: true
      };
  },
  watch: {
      deviceSelected(currentValue) {
        this.addDevice(currentValue);
      }
  },
  mounted() {
    let _this = this;
    this.$http.get("/sensors").then(response => {
      if(response.data.status === "success") {
        _this.sensors =  response.data.sensors.map(a => { return {label: a.title, value: a.id};});
        this.deviceSelected = _this.sensors[0].value;
      }
    });
  },
  methods: {
      toggleMeasurements(serie, measurement) {
        serie = 'd_'+serie;
        this.$refs.chart.chart.series.forEach((ele) => {
            if (ele.options.device == serie && ele.options.measurement == measurement) {
                !ele.visible ? ele.show(): ele.hide()
            }
        });
      },
      toggleDevice(deviceName) {
        for(let i=0; i<this.$refs.chart.chart.series.length; i++){
            if(this.$refs.chart.chart.series[i].options.device == "d_"+deviceName) {
                let serie = this.$refs.chart.chart.series[i];
                if(serie.visible) {
                    serie.show();
                    this.$toast.info("Enabled device on graph.");
                } else {
                    serie.hide();
                    this.$toast.info("Disabled device on graph.");
                }
                return true;
            }
        }
        return false;
      },
      addDevice(deviceName) {
          let _this = this;
          if( this.toggleDevice(deviceName) ) return;

          //Device not toggled, so not yet in memory, loading new one
          this.$toast.info("Loading sensor data.");
          this.$http.get("/sensors/device",{
              params: {
                  "device": deviceName
              }
          }).then(function(result) {
            if(result.data.status == "success") {
                const item = result.data.sensor;

                console.log("Sensor type: " + item.type);
                let measurements = JSON.parse(item.measurements);

                console.log(measurements);
                _this.seriesButtons = [];
                _this.chart.series = [];
                measurements.forEach((measurement) => {
                  if(measurement.indexOf("vdd") == -1 && measurement.indexOf("motion") == -1) {
                    _this.seriesButtons.push({name: measurement, disabled: false});
                    console.log(_this.seriesButtons);
                    let data = item.points.map(a => {
                      return [new Date(a.time).getTime(), a[measurement]];
                    });
                    _this.last_values[measurement] = data[data.length-1][1].toFixed(2);
                    console.log(item.points);

                    _this.chart.series.push({
                        device: "d_"+deviceName,
                        measurement: measurement,
                        name: deviceName + " " + measurement,
                        data: data,
                        type: 'line',
                        smooth: true,
                        symbolSize: 8,
                        tooltip: {
                          pointFormatter: function() {
                            return this.series.name+" "+this.y.toFixed(2);
                          }
                        },
                        lineStyle: {
                            color: '#ff5721',
                            opacity: 1,
                            width: 1.5,
                        },
                        itemStyle: {
                            color: '#fff',
                            borderColor: '#ff5721',
                            borderWidth: 1.5
                        }
                    });
                  }
                });
                _this.$toast.success("Sensor data added.");
            }
            });
        }
    }
};
</script>

<style>
.spinner.sm {
  height: 2em;
  width: 2em;
}

.demo {
  margin-top:2em;
  padding-top:1em;
  border-radius: 15px;
  justify-content:flex-start;
}

.measurement {
  background: rgba(143, 188, 143, 0.8) !important;
  border-radius: 10px;
  padding: 1em;
  text-align: center;
  padding-top: 2em;
  padding-bottom: 2em;
  border: 0;
  margin-left:1em;
  margin-right:1em;
  cursor: pointer;
  margin-bottom: 1em;
  transition: all 0.2s ease-in;
}

.measurement.disabled {
  background: rgba(60, 60, 60, 0.5) !important;
}

.measurement:hover {

  background: rgba(143, 188, 143, 1) !important;

}
</style>
